import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Header from "./Components/Header";
import Home from './Components/Home';
import WhatWeDo from './Components/Whatwedo/WhatWeDo';
import HowWeWork from './Components/HowWeWork';
import Footer from './Components/Footer';
import WhatsAppButton from './Components/WhatsAppBtn';
import Alternative from './Components/Alternative';
import OurWorks from './Components/OurWorks';
import ContactModal from './Components/ContactModal';
import Blog from './Components/Blogs/Blog'; 
import BlogDetails from './Components/Blogs/BlogDetails';
import Product from './Components/Product';
import PrivacyPolicy from './Components/Legal/PrivacyPolicy';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [isModalOpen, setModalOpen] = useState(location.pathname === "/contact");

  useEffect(() => {
    setModalOpen(location.pathname === "/contact");
  }, [location.pathname]);

  const openModal = () => navigate("/contact");
  const closeModal = () => navigate("/");

  return (
    <div className="App">
      <Header openContactModal={openModal} />
      <main>
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Home openContactModal={openModal} />
                <Alternative />
                <WhatWeDo openContactModal={openModal} />
                <Product />
                <OurWorks />
                {/* <HowWeWork /> */}
              </>
            } 
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>

      <Footer />
      <WhatsAppButton />

      <ContactModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default App;
