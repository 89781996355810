import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import FbIcon from "../Assets/icons/facebook.png";
import InstaIcon from "../Assets/icons/instagram.png";
import LinkedinIcon from "../Assets/icons/linkedin.png";
import { FaWhatsapp } from "react-icons/fa";
import { MdShare } from "react-icons/md";

const WhatsAppButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSocialIcons, setShowSocialIcons] = useState(false);
  const phoneNumber = "9962744380";

  useEffect(() => {
    const handleScroll = () => {
      const homeSection = document.getElementById("home");
      if (homeSection) {
        const homeHeight = homeSection.offsetHeight;
        setShowSocialIcons(window.scrollY > homeHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const socialItems = [
    { img: FbIcon, alt: "Facebook", bg: "bg-white", url: "https://www.facebook.com/people/Namuvi/61558573185986/" },
    { img: InstaIcon, alt: "Instagram", bg: "bg-white", url: "https://www.instagram.com/namuvi_tech/" },
    { img: LinkedinIcon, alt: "LinkedIn", bg: "bg-white", url: "https://www.linkedin.com/company/namuvi-technologies-private-limited/" },
  ];
  
  

  return (
    <section className="fixed bottom-4 right-4 z-20 flex flex-col items-end">
      <a
        href={`https://wa.me/${phoneNumber}?text=Hello, How can we help you?`}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-btn fixed bottom-4 right-[70px] z-50 p-3 bg-green-600 text-white md:bg-gray-50 md:text-green-600 rounded-full shadow-xl flex items-center space-x-1"
      >
        <FaWhatsapp className="text-white md:text-green-600" size={24} />
        <span className="text-black font-bold uppercase hidden md:block">Chat</span>
      </a>

      {showSocialIcons && (
        <>
          <motion.ul
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: isOpen ? 1 : 0, scale: isOpen ? 1 : 0 }}
            className="py-2 flex flex-col items-end space-y-2 "
          >
            {socialItems.map((item, index) => (
              <motion.li
                key={index}
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: isOpen ? 0 : 20, opacity: isOpen ? 1 : 0 }}
                transition={{ delay: isOpen ? index * 0.1 : (socialItems.length - index) * 0.1 }}
                className="relative group"
              >
                 <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`w-12 h-12 rounded-full flex items-center justify-center ${item.bg} shadow-lg`}
              >
                <img src={item.img} alt={item.alt} className="w-6 h-6" />
              </a>
              </motion.li>
            ))}
          </motion.ul>

          <button
            onClick={toggleMenu}
            className="w-12 h-12 bg-[#F5A841]  rounded-full flex items-center justify-center shadow-lg"
          >
            <MdShare size={26} />
          </button>
        </>
      )}
    </section>
  );
};

export default WhatsAppButton;
