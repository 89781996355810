import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ReactTyped } from 'react-typed';
import BackgroundSvg from './BackgroundSvg';
import About from './About';
import { TiSocialFacebook } from "react-icons/ti";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

const Home = ({ openContactModal }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const socialLinks = [
    {
      icon: TiSocialFacebook,
      url: "https://www.facebook.com/people/Namuvi/61558573185986/",
    },
    {
      icon: FaInstagram,
      url: "https://www.instagram.com/namuvi_tech/",
    },
    {
      icon: FaLinkedin,
      url: "https://www.linkedin.com/company/namuvi-technologies-private-limited/",
    },
  ];

  const letters = "Follow  Us".split("");

  // Wave animation variant
  const waveVariants = {
    initial: { y: 0, opacity: 0 },
    animate: (i) => ({
      y: [0, -7, 0], // Moves up and down
      opacity: [0, 1, 1, 0.8, 1],
      transition: {
        duration: 0.8,
        ease: "easeInOut",
        delay: i * 0.1, // Delay effect for smooth wave
        repeat: Infinity,
        repeatType: "reverse",
      },
    }),
  };

  return (
    <section className='relative'>
      <BackgroundSvg />
      <main className="home relative flex overflow-hidden" id='home'>
        <div className="h-screen w-full">
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_20px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]"></div>
          <section className="max-w-[1240px] mx-auto mt-52 md:mt-40 z-20">
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1 }}
              className="flex flex-col gap-6 items-center font-bold text-4xl text-center md:text-8xl drop-shadow-md"
            >
              <motion.p
                initial={{ opacity: 0, x: -50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
                className=''
              >
                Let's build your
              </motion.p>

              <motion.p
                initial={{ opacity: 0, x: 50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <span className="">digital{" "}</span>

                <ReactTyped
                  strings={['Empire', 'Brand', 'Platform']}
                  typeSpeed={70}
                  backSpeed={70}
                  loop
                  showCursor={false}
                  className="text-[#F5A841] drop-shadow-md"
                />
              </motion.p>
              <motion.p
                initial={{ opacity: 0, x: -50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 2 }}
              >
                together
              </motion.p>

              <button
                className="button"
                onClick={openContactModal}
              >
                Let's Build!!
              </button>
            </motion.div>
          </section>
        </div>

        <div className='hidden md:flex'>
        <div className="absolute top-1/2 right-1 transform -translate-y-1/2 flex flex-col items-center text-[#1F2937] gap-4">
      {/* Animated Social Media Icons */}
      {socialLinks.map(({ icon: Icon, url }, i) => (
        <motion.a
           key={i}
           href={url}
           rel="noreferrer"
           target="_blank"
          // custom={i}
          // variants={waveVariants}
          // initial="initial"
          // animate="animate"
        >
          <Icon className="w-7 h-7 cursor-pointer hover:scale-125 transition-transform" />
        </motion.a>
      ))}

      {/* Animated "Follow Us" Text */}
      <h1 className="-rotate-90 mt-10 text-xl flex">
        {letters.map((letter, i) => (
          <motion.span
            key={i}
            custom={i}
            variants={waveVariants}
            initial="initial"
            animate="animate"
            className="inline-block"
          >
            {letter}
          </motion.span>
        ))}
      </h1>
    </div>
        </div>
      </main>
      <About />
    </section>
  );
};

export default Home;

{/* <div className="absolute top-1/2 right-1 transform -translate-y-1/2 flex flex-col items-center text-[#1F2937] gap-4 ">
            <a
              href="https://www.facebook.com/people/Namuvi/61558573185986/"
              rel="noreferrer"
              target="_blank"
            >
              <TiSocialFacebook className="w-7 h-7 cursor-pointer hover:scale-125 transition-transform" />
            </a>
            <a
              href="https://www.instagram.com/namuvi_tech/"
              rel="noreferrer"
              target="_blank"
            >
              <FaInstagram className="w-7 h-7 cursor-pointer hover:scale-125 transition-transform" />
            </a>
            <a
              href="https://www.linkedin.com/company/namuvi-technologies-private-limited/"
              rel="noreferrer"
              target="_blank"
            >
              <FaLinkedin className="w-7 h-7 cursor-pointer hover:scale-125 transition-transform" />
            </a>
            <h1 className="-rotate-90 mt-6 text-xl">Follow Us</h1>
          </div> */}